import * as chrono from 'chrono-node'
import { differenceInWeeks, differenceInYears } from 'date-fns/fp'
import { Field, useFormikContext } from 'formik'
import { find } from 'lodash'
import * as yup from 'yup'
import { chronoDate } from '../../../schema/date'
import DateInput from '../../DateInput'
import SelectField from '../../SelectField'
import SubmitButton from '../../SubmitButton'
import TextInput from '../../TextInput'

const casualGbChrono = new chrono.Chrono(chrono.en.createCasualConfiguration(true))

export const TableBooleanField = ({
  label,
  name,
  value,
  edit = false,
}: {
  label: string
  name: string
  value: boolean
  edit?: boolean
}) => {
  if (edit) {
    return (
      <tr>
        <th>{label}:</th>
        <td>
          <SelectField name={name}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </SelectField>
        </td>
      </tr>
    )
  } else if (value) {
    return (
      <tr>
        <th>{label}:</th>
        <td>{value ? 'Yes' : 'No'}</td>
      </tr>
    )
  } else {
    return <></>
  }
}

export const TableTextField = ({
  label,
  name,
  value,
  type = 'text',
  edit = false,
}: {
  label: string
  name: string
  value: string
  type?: 'text' | 'date' | 'textarea'
  edit?: boolean
}) => {
  if (edit) {
    return (
      <tr>
        <th>{label}:</th>
        <td>
          {type === 'date' && <DateInput name={name} label=" " placeholder="" chronoInstance={casualGbChrono} />}
          {type === 'text' && <TextInput name={name} type="text" />}
          {type === 'textarea' && <Field className="textarea is-fullwidth p-4" as="textarea" name={name} />}
        </td>
      </tr>
    )
  } else if (value) {
    return (
      <tr>
        <th>{label}:</th>
        <td>{value}</td>
      </tr>
    )
  } else {
    return <></>
  }
}

export const TableSelectField = ({
  label,
  name,
  value,
  options,
  allowBlank = false,
  edit = false,
}: {
  label: string
  name: string
  value: string
  options?: {
    label: string
    value: string
  }[]
  allowBlank?: boolean
  edit?: boolean
}) => {
  if (edit) {
    return (
      <tr>
        <th>{label}:</th>
        <td>
          <SelectField name={name}>
            {allowBlank && <option></option>}
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </SelectField>
        </td>
      </tr>
    )
  } else if (value) {
    return (
      <tr>
        <th>{label}:</th>
        <td>{find(options, { value }).label}</td>
      </tr>
    )
  } else {
    return <></>
  }
}

export const TextAreaField = ({ name, value, edit }: { name: string; value: string; edit?: boolean }) => {
  if (edit) {
    return (
      <Field
        className="textarea is-fullwidth p-4"
        as="textarea"
        name={name}
        placeholder="Add any useful information here..."
      />
    )
  } else {
    return <p className="p-4">{value}</p>
  }
}

export const Submit = ({
  name,
  value,
  className,
  children,
}: {
  name?: string
  value?: string
  className?: string
  children?: React.ReactNode
}) => {
  const { isSubmitting, setFieldValue } = useFormikContext()

  return (
    <SubmitButton
      name={name}
      submitting={isSubmitting}
      className={className}
      value={value}
      onClick={(event) => {
        if (name && value) {
          setFieldValue(name, value)
        }
      }}
    >
      {children}
    </SubmitButton>
  )
}

export const validationString = (label: string, regex?: RegExp, regexMessage?: string) => {
  let validation = yup.string().label(label)

  if (regex) {
    validation = validation.matches(regex, regexMessage)
  }

  validation = validation.required()

  return validation
}

export const validationDate = (label: string, type?: 'dob') => {
  let validation = chronoDate(casualGbChrono).label(label).typeError('Not a valid date').required()

  switch (type) {
    case 'dob':
      validation = validation.test(
        'is-reasonable-age',
        'Date of Birth must be between 1 week and 150 years old',
        (value) => {
          try {
            const now = new Date()
            return differenceInWeeks(value, now) > 1 && differenceInYears(value, now) < 150
          } catch (err) {
            return true
          }
        }
      )
      break
  }

  return validation
}
