import cx from 'classnames'
import { Field, FieldValidator } from 'formik'

interface SelectFieldProps {
  name: string
  label?: React.ReactNode
  validate?: FieldValidator
  className?: string
  labelClassName?: string
  children: React.ReactNode
}

const SelectField = ({ name, label, validate, className, labelClassName, children }: SelectFieldProps) => {
  return (
    <Field name={name} validate={validate}>
      {({ field, meta }) => (
        <div className="field">
          {label && <label className={`label is-size-6 has-text-weight-light ${labelClassName}`}>{label}</label>}

          <div className="control">
            <div
              className={cx('select', {
                'is-danger': meta.touched && meta.error,
              })}
            >
              <select className={className} {...field}>
                {children}
              </select>
            </div>

            {meta.touched && meta.error && <p className="help is-danger">{meta.error}</p>}
          </div>
        </div>
      )}
    </Field>
  )
}

export default SelectField
