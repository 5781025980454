import { gql, useApolloClient } from '@apollo/client'
import cx from 'classnames'
import SubmitButton from 'components/SubmitButton'
import { Form, Formik } from 'formik'
import { Link } from 'gatsby'
import { useEffect, useState } from 'react'
import { FaFilePdf } from 'react-icons/fa'
import { StringParam, useQueryParam } from 'use-query-params'
import * as yup from 'yup'
import { BulmaMessage } from '../components/Bulma'
import { validationString } from '../components/Dashboard/RecoveryCertificate/Fields'
import b64toBlob from '../helpers/b64ToBlob'
import { fromUTCISOStringToLondon } from '../helpers/date'
import rollbar from '../helpers/rollbar'
import {
  ViewRecoveryCertificateQuery,
  ViewRecoveryCertificateQueryVariables,
  ViewRecoveryCertificateQuery_recoveryCertificateDetails,
} from './__generated__/ViewRecoveryCertificateQuery'
import TextInput from '../components/TextInput'

export const recoveryCertificateDetailsQuery = gql`
  query ViewRecoveryCertificateQuery($orderId: ID!, $UUID: ID!, $dateOfBirth: String!) {
    recoveryCertificateDetails(orderId: $orderId, UUID: $UUID, dateOfBirth: $dateOfBirth) {
      orderId
      firstName
      lastName
      dateOfBirth
      passportNumber
      positiveCovidTestDate
      typeOfCovidTest
      lastTimeExperiencedSymptoms
      approvedAt
      certificateFile
      success
    }
  }
`

export const ViewRecoveryCertificateAuth = () => {
  const client = useApolloClient()
  const [orderId] = useQueryParam('orderId', StringParam)
  const [UUID] = useQueryParam('key', StringParam)
  const [dob] = useQueryParam('dob', StringParam)
  const [dateOfBirth, setDateOfBirth] = useState<string>(dob)

  const [order, setOrder] = useState<ViewRecoveryCertificateQuery_recoveryCertificateDetails>()
  const [errorMessage, setErrorMessage] = useState<JSX.Element>()

  const validationSchema = yup.object().shape({
    dateOfBirth: validationString(
      'Date of Birth',
      /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
      'Must be in the format dd/mm/yyyy e.g. 01/01/1980'
    ),
  })

  useEffect(() => {
    if (dateOfBirth && UUID) {
      findOrder({ orderId, dateOfBirth, UUID })
    }
  }, [dateOfBirth, UUID])

  const findOrder = async ({ orderId, dateOfBirth, UUID }) => {
    const { data } = await client.query<ViewRecoveryCertificateQuery, ViewRecoveryCertificateQueryVariables>({
      query: recoveryCertificateDetailsQuery,
      variables: {
        orderId,
        dateOfBirth: dateOfBirth?.split('/').reverse().join('-'),
        UUID,
      },
      fetchPolicy: 'no-cache',
    })

    if (data?.recoveryCertificateDetails?.orderId) {
      setOrder(data?.recoveryCertificateDetails)
    } else {
      setErrorMessage(
        <p>
          Unable to validate details. If you think this is an error please{' '}
          <Link to="/contact">contact our support team</Link>.
        </p>
      )
    }
  }

  const onSubmit = (data) => {
    setErrorMessage(undefined)
    setDateOfBirth(data.dateOfBirth)
    return true
  }

  return (
    <>
      {order ? (
        <RecoveryCertificateDetails order={order} />
      ) : (
        <>
          {errorMessage && <BulmaMessage color="danger">{errorMessage}</BulmaMessage>}

          <section className="section">
            <div className="container">
              <h1 className="title has-text-midnightBlue is-size-4-mobile has-text-centered">
                View Recovery Certificate
              </h1>
              <div className="has-width-large margin-auto box mt-6">
                <h2 className="subtitle mb-5 has-text-midnightBlue">
                  Please enter your Date of Birth to view your certificate
                </h2>
                <Formik
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  initialValues={{
                    orderId,
                  }}
                >
                  {(formik) => (
                    <Form>
                      <div className="has-text-centered mb-5">
                        <TextInput
                          name="dateOfBirth"
                          label="Date of Birth"
                          placeholder="01/01/1980"
                          className="is-fullwidth"
                        />
                      </div>
                      <div className="has-text-centered">
                        <SubmitButton
                          submitting={formik.isSubmitting}
                          className="button is-rounded is-midnightBlue is-centered"
                          key="submit"
                        >
                          View Certificate
                        </SubmitButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export const RecoveryCertificateDetails = ({
  order,
}: {
  order: ViewRecoveryCertificateQuery_recoveryCertificateDetails
}) => {
  const [downloadError, setDownloadError] = useState(false)
  const [loading, setLoading] = useState(false)

  const onDownloadPdf = async () => {
    try {
      setLoading(true)
      setDownloadError(false)

      const filename = `RecoveryCertificate - ${order.orderId} - Certificate.pdf`

      const blob = b64toBlob(order.certificateFile, 'application/pdf')
      const a = document.createElement('a')
      const url = URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.onclick = () => {
        setTimeout(() => URL.revokeObjectURL(url), 100)
      }
      a.click()
    } catch (err) {
      rollbar.error('Error downloading recovery certificate PDF', err, {
        orderId: order?.orderId,
      })
      setDownloadError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="title has-text-midnightBlue is-size-4 mobile">Coronavirus Recovery Certificate Validation</h1>

          {order.approvedAt && (
            <>
              <h2 className="subtitle mt-2 has-text-armyGreen">Valid Coronavirus Recovery Certificate</h2>
              <p>
                <b>Certificate Reference:</b> {order.orderId}
              </p>
              <p>
                <b>Certificate Issue Date:</b> {fromUTCISOStringToLondon(order.approvedAt)}
              </p>
              <p>
                <b>Name:</b> {order.firstName + ' ' + order.lastName}
              </p>
              <p>
                <b>Date of Birth:</b> {fromUTCISOStringToLondon(order.dateOfBirth)}
              </p>
              <p className="mt-5">
                <button
                  type="button"
                  className={cx('button is-midnightBlue', {
                    'is-loading': loading,
                  })}
                  onClick={onDownloadPdf}
                >
                  <FaFilePdf className="mr-3" />
                  Download PDF Certificate
                </button>
              </p>

              {downloadError && (
                <BulmaMessage color="danger">
                  <p>
                    Something went wrong downloading your certificate, we've notified. Please try again later or{' '}
                    <Link to="/contact">contact our support team</Link>.
                  </p>
                </BulmaMessage>
              )}
            </>
          )}
          {!order.approvedAt && (
            <>
              <h2 className="subtitle mt-2 has-text-danger">Not a valid recovery certificate</h2>
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default ViewRecoveryCertificateAuth
